import React, {useContext} from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import imgL1FeatureOval from "../../assets/image/png/l1-feature-oval.png";
import imgL1FeatureCurve from "../../assets/image/svg/l1-curve-feature.svg";
import { device } from "../../utils";
import GlobalContext from "../../context/GlobalContext";

const ShapeTopRight = styled(Box)`
  position: absolute;
  top: 0;
  right: 0px;
`;

const ShapeBottmRight = styled(Box)`
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  img {
    min-width: 100%;
  }
`;

const FeaturedBox = styled(Box)`
  height: 250px;
  @media ${device.lg} {
    height: 310px;
  }
  @media ${device.xl} {
    height: 330px;
  }
`;

const FeatureCard = ({
  color = "primary",
  iconName,
  title,
  children,
  ...rest
}) => (
  <FeaturedBox
    bg="light"
    py="25px"
    px="30px"
    borderRadius={10}
    mb={4}
    {...rest}
  >
    <Box
      size={69}
      borderRadius="50%"
      color={color}
      fontSize="28px"
      className="d-flex justify-content-center align-items-center"
      css={`
        background-color: ${({ theme, color }) =>
          rgba(theme.colors[color], 0.1)};
      `}
    >
      <i className={`icon ${iconName}`}></i>
    </Box>
    <div>
      <Text
        color="heading"
        as="h3"
        fontSize={4}
        fontWeight={500}
        letterSpacing={-0.75}
        my={3}
      >
        {title}
      </Text>
      <Text fontSize={2} lineHeight={1.75} className="text-start">
        {children}
      </Text>
    </div>
  </FeaturedBox>
);

const Feature = () => {
  const gContext = useContext(GlobalContext);

  return (
    <>
      {/* <!-- Feature section --> */}
      <Section bg="primary" className="position-relative">
        <ShapeTopRight
          data-aos="fade-left"
          data-aos-duration="750"
          data-aos-once="true"
        >
          <img src={imgL1FeatureOval} alt="" className="img-fluid" />
        </ShapeTopRight>
        <ShapeBottmRight>
          <img src={imgL1FeatureCurve} alt="" className="img-fluid" />
        </ShapeBottmRight>
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className="pl-lg-5 order-lg-2">
              <div className="feature-content section-title">
                <Title color="light">Bring them back with a smile.</Title>
                <Text color="light" opacity={0.9} mb={4} className="text-start">
                  Start building loyal customers in minutes, know what your
                  customers like about your business, turn your loyal customers
                  into brand ambassadors, engage with customers regularly
                  through automation and stay ahead of your competition.
                </Text>

                <div className="header-btns ml-auto ml-lg-0 d-none d-md-block">
                  <Button
                    variant="outline"
                    size="sm"
                    css={`
                      font-size: 16px !important;
                      min-width: 141px !important;
                      height: 45px !important;
                      letter-spacing: 0.1px !important;
                    `}
                    onClick={() => window.location.href= process.env.GATSBY_STORE_REGISTER_URL}
                  >
                    Get Started
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg="6" className="order-lg-1 mt-5 mt-lg-0">
              <Row>
                <Col md="6">
                  <FeatureCard
                    color="primary"
                    iconName="far fa-user-circle"
                    title="Rewards"
                  >
                    Automated customer enrollment that builds loyal customers 10
                    times faster.
                  </FeatureCard>
                </Col>
                <Col md="6">
                  <FeatureCard
                    color="primary"
                    iconName="fas fa-poll-h"
                    title="Feedback"
                  >
                    Built-in customer feedback that makes your customers
                    involved and feel special.
                  </FeatureCard>
                </Col>
                <Col md="6">
                  <FeatureCard
                    color="primary"
                    iconName="fas fa-funnel-dollar"
                    title="Promotions"
                  >
                    Automated promotions to retain customers and improve
                    engagement.
                  </FeatureCard>
                </Col>
                <Col md="6">
                  <FeatureCard
                    color="primary"
                    iconName="fas fa-bullhorn"
                    title="Referrals"
                  >
                    Turn your loyal customers into brand advocates and increase
                    referral business.
                  </FeatureCard>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Feature;
