import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { rgba } from "polished";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";
import promotionContentMobile from "../../assets/image/png/promotion-content.png";

// const TopCard = styled(Box)`
//   width: auto;
//   box-shadow: ${({ theme }) => `0 32px 34px ${theme.colors.shadow}`};
//   position: absolute;
//   top: 18%;
//   left: 0;
//   @media ${device.md} {
//     left: 0%;
//   }
//   @media ${device.lg} {
//     left: -10%;
//   }
// `;

const BottomCard = styled(Box)`
  width: auto;
  box-shadow: ${({ theme }) => `0 32px 34px ${theme.colors.shadow}`};
  position: absolute;
  bottom: 10%;
  right: 0;
  @media ${device.md} {
    right: -1%;
  }
  @media ${device.lg} {
    right: -5%;
  }
  @media ${device.xl} {
    right: -11%;
  }
`;

const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 0px;
  padding-left: 0;

  @media ${device.sm} {
    padding-top: 0px;
    display: flex;
    flex-wrap: wrap;
  }
  @media ${device.lg} {
    justify-content: space-between;
  }

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #19191b;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 28px;
    display: flex;
    margin-bottom: 32px;

    &:before {
      content: "\f662";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 20px;
      width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.primary};
      position: relative;
      top: 1px;
      margin-right: 13px;
    }
  }
`;

const ImageRight = styled(Box)`
  img {
    box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
    border-radius: 10px;
  }
`;

const SecondText = styled.span`
  padding-left: 0px;
  display: block;
`;

const Content1 = () => (
  <>
    {/* <!-- Content section 1 --> */}
    <Section id={"promotions"}>
      <Container>
        <Row className="align-items-center">
          <Col md="12" lg="6" className="order-lg-2">
            <div className="position-relative pl-lg-5">
              <ImageRight
                className="pl-lg-4 text-center text-lg-right"
                data-aos="fade-down"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <img
                  src={promotionContentMobile}
                  alt=""
                  className="img-fluid"
                />
              </ImageRight>

              {/* <TopCard
                bg="primary"
                p="18px"
                borderRadius={8}
                className="d-flex align-items-center"
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="1000"
              >
                <Box
                  width="30px"
                  minWidth="30px"
                  height="30px"
                  minHeight="30px"
                  bg="secondary"
                  color="light"
                  borderRadius="50%"
                  className="d-flex align-items-center justify-content-center"
                  mr={3}
                >
                  <i className="fas fa-wallet"></i>
                </Box>
                <Text
                  color="white"
                  lineHeight="24px"
                  fontSize={1}
                  mb={0}
                  variant={"small"}
                >
                  Celebrate your birthday with us and get 50% OFF 😊
                </Text>
              </TopCard> */}

              <BottomCard
                bg="secondary"
                p="18px"
                borderRadius={8}
                className="d-flex align-items-center text-left"
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="100"
              >
                <Box
                  width="30px"
                  minWidth="30px"
                  height="30px"
                  minHeight="30px"
                  bg="primary"
                  color="light"
                  borderRadius="50%"
                  className="d-flex align-items-center justify-content-center"
                  mr={3}
                >
                  <i className="fas fa-comment-alt"></i>
                </Box>
                <Text color="dark" variant={"small"}  fontSize={1}
                    lineHeight="24px">
                  Happy Birthday Michelle!
                  <SecondText>
                    Celebrate your birthday with us and get 50% OFF{" "}
                    <span role="img" aria-label="smile">😊</span>
                  </SecondText>
                </Text>
              </BottomCard>
            </div>
          </Col>
          <Col lg="6" md="9">
            <Box className="pt-5 pt-lg-0">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <Box>
                  <Title>Promotions</Title>
                  <Text mb={4} className="text-start">
                    Create automated offers and promotions for the entire year
                    and let RewardUp handle the rest. Send birthday and
                    anniversary wishes or special promotions to your loyal
                    customers automatically. Publish holiday and special
                    occasion promotions on all possible in-store and social
                    media platforms effortlessly using automated promotions
                    feature. Save more time but engage often with your customers
                    and audience through any medium they prefer.
                  </Text>
                </Box>
              </div>
              <Box mt={1} pt={1}>
                <ULStyled>
                  <li>
                    Build lifelong relationships with your customers by
                    celebrating their birthdays and anniversaries.
                  </li>
                  <li>
                    Upsell more while your customers are in-store by using
                    remotely managed digital signages.
                  </li>
                  <li>
                    Deliver special coupons or offers to a specific customer
                    group or a privileged customer.
                  </li>
                </ULStyled>
              </Box>
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content1;
