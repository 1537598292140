import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";
import loyaltyListIcon from "../../assets/image/badges.svg";
import loyaltyContentBig from "../../assets/image/png/loyalty-content.png";

const ShapeCard = styled(Box)`
  width: auto;
  box-shadow: 0 32px 34px rgba(28, 27, 32, 0.2);
  position: absolute;
  top: 39%;
  left: 0%;
  transform: scale(0.85);
  @media ${device.sm} {
    left: 0;
    transform: scale(1);
    top: 30%;
  }
  @media ${device.md} {
    left: 0%;
    top: 30%;
  }
  @media ${device.lg} {
    left: 0%;
    top: 38%;
  }
  @media ${device.xl} {
    left: 0%;
    top: 38%;
  }
`;

const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" height={40} width={40} />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImageRight = styled(Box)`
  img {
    box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
    border-radius: 10px;
  }
`;

const SecondText = styled.span`
  padding-left: 0px;
  display: block;

  @media ${device.xl} {
    padding-left: 5px;
    display: initial;
  }
`;

const Content1 = () => (
  <>
    {/* <!-- Content section 1 --> */}
    <Section id={"rewards"}>
      <Container>
        <Row className="align-items-center">
          <Col md="12" lg="6" className="order-lg-2">
            <div className="position-relative pl-lg-5">
              <ImageRight
                className="pl-lg-4 text-center text-lg-right"
                data-aos="fade-down"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <img
                  src={loyaltyContentBig}
                  alt=""
                  className="img-fluid mt-0"
                />
              </ImageRight>

              <ShapeCard
                bg="primary"
                p="18px"
                borderRadius={8}
                className="d-flex align-items-center"
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="100"
              >
                <Box
                  width="30px"
                  minWidth="30px"
                  height="30px"
                  minHeight="30px"
                  bg="secondary"
                  color="light"
                  borderRadius="50%"
                  className="d-flex align-items-center justify-content-center"
                  mr={3}
                  mt={0}
                >
                  <i className="fas fa-bell"></i>
                </Box>

                <Box>
                  <Text
                    fontSize={1}
                    lineHeight="24px"
                    color={"white"}
                    mb={0}
                    className="text-wrap"
                    variant={"small"}
                  >
                    Thank you for your purchase!
                    <SecondText>You earned 45 points.</SecondText>
                  </Text>
                </Box>
              </ShapeCard>
            </div>
          </Col>
          <Col lg="6" md="9">
            <Box className="pt-5 pt-lg-0">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <Box>
                  <Title>Rewards</Title>
                  <Text mb={4} className="text-start">
                    Create your own branded rewards program and build loyal
                    customers faster using RewardUp’s automated customer
                    enrollment. Offer rewards based on spend or visit and keep
                    track of your customer purchase behaviour and spend. Drive
                    traffic during non peak hours or not busy days using SMS,
                    email and push notifications. RewardUp swifts the customer
                    sign-up burden from your staffs and allows them spend more
                    time upselling and improve customer service.
                  </Text>
                </Box>
              </div>
              <Box mt={4} pt={3}>
                <ContentCard
                  title="Know your high value customers and keep them privileged"
                  iconImage={loyaltyListIcon}
                  mb={4}
                >
                  Increasing customer retention rates by 5% increases profits
                  anywhere from 25% to 95%.
                </ContentCard>
                <ContentCard
                  title="Meet your customer expectations and stay ahead of the competition"
                  iconImage={loyaltyListIcon}
                  mb={4}
                >
                  86% of the consumers prefer purchasing from brands that offer
                  a rewards program.
                </ContentCard>
                <ContentCard
                  title="Build loyal customers to increase your revenue"
                  iconImage={loyaltyListIcon}
                >
                  Returning customers spend 200% more and more often become your
                  brand advocates.
                </ContentCard>
              </Box>
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content1;
