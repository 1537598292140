import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Button, Section, Box, Text } from "../../components/Core";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const HeroCon = styled.div`
    position: relative;
    background-color: rgba(0, 0, 0, 0.6);
`;

const HeroSection = styled(Section)`
    min-height: 100vh;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
`;

const BannerImg = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

    .banner-img { 
        display: none;
    }

    .banner-img-mobile { 
        display: block;
    }

    @media only screen and (min-width: 575px) {
        .banner-img-mobile {
            display: none;
        }
        .banner-img { 
            display: block;
        }
    }
`;

const HeroText = styled.span`
    color: #fff;
`;

const Hero = () => {
    const { banner, bannerMobile } = useStaticQuery(graphql`
        {
            banner: file(relativePath: { eq: "home/banner.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            bannerMobile: file(relativePath: { eq: "home/banner-mobile.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return (
        <>
            {/* <!-- Hero Area --> */}
            <HeroCon>
                <BannerImg>
                    <Img
                        className="banner-img"
                        fluid={banner?.childImageSharp?.fluid}
                        style={{ width: "100%", height: "100%" }}
                        objectFit="cover"
                    />
                    <Img
                        fluid={bannerMobile?.childImageSharp?.fluid}
                        style={{ width: "100%", height: "100%" }}
                        objectFit="cover"
                        className="banner-img-mobile"
                    />
                </BannerImg>
                <HeroSection>
                    <div className="pt-5"></div>
                    <Container>
                        <Row className="justify-content-start align-items-center">
                            {/* <Col lg="5" md="8" sm="9" className="order-lg-2"></Col> */}
                            <Col lg="9" md="10" sm="9" className="order-lg-1">
                                <div
                                    data-aos="fade-right"
                                    data-aos-duration="750"
                                    data-aos-once="true"
                                    data-aos-delay="500"
                                >
                                    <Box
                                        py={[null, null, null, 5]}
                                        pr={5}
                                        pt={[4, null]}
                                    >
                                        <Title variant="hero">
                                            <HeroText>
                                                Turn your customers into brand
                                                ambassadors
                                            </HeroText>
                                        </Title>
                                        <Text mb={4}>
                                            <HeroText>
                                                Easy to use customer engagement
                                                platform that manages customer rewards, referrals,
                                                feedbacks and social reputation.
                                            </HeroText>
                                        </Text>
                                        <div className="header-btns ml-auto ml-lg-0">
                                            <Button
                                                size="sm"
                                                css={`
                                                    font-size: 16px !important;
                                                    min-width: 141px !important;
                                                    height: 45px !important;
                                                    letter-spacing: 0.1px !important;
                                                    margin-right: 10px;
                                                `}
                                                onClick={() => window.location.href= process.env.GATSBY_STORE_REGISTER_URL}
                                            >
                                                Get Started
                                            </Button>
                                            <Button
                                                size="sm"
                                                css={`
                                                    font-size: 16px !important;
                                                    min-width: 141px !important;
                                                    height: 45px !important;
                                                    letter-spacing: 0.1px !important;
                                                `}
                                                onClick={() => window.open("https://calendly.com/d/cpj6-qsj-rkz/rewardup-demo")}
                                            >
                                                Book Demo
                                            </Button>
                                        </div>
                                    </Box>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </HeroSection>
            </HeroCon>
        </>
    );
};

export default Hero;
