import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

import svgCurve from "../../assets/image/svg/l1-curve-content2.svg";
// import imgContentMobile from "../../assets/image/png/content-img2--mobile.webp";

const WidgetContainer = styled(Box)`
    counter-reset: widget-counter;
`;

const WidgetStyled = styled(Box)`
    padding-left: 70px;
    position: relative;
    max-width: auto;
    justify-content: start;
    align-items: center;
    display: flex;

    &:before {
        counter-increment: widget-counter;
        content: "\f004";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        left: 0;
        min-width: 49px;
        width: 49px;
        height: 49px;
        background-color: ${({ theme }) => theme.colors.primary};
        border-radius: 500px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: ${({ theme }) => theme.colors.light};
        font-size: 24px;
        font-weight: 700;
        letter-spacing: -0.75px;
    }
`;

const Widget = ({ num = 1, title = "", children = "", ...rest }) => {
    return (
        <WidgetStyled {...rest}>
            <Title variant="card" mb={2}>
                {title}
            </Title>
            <Text variant="small">{children}</Text>
        </WidgetStyled>
    );
};

const ShapeTop = styled(Box)`
    position: absolute;
    top: 0;
    left: -150px;
    img {
        min-width: 100%;
    }
`;

const ShapeCard = styled(Box)`
    width: auto;
    box-shadow: ${({ theme }) => `0 32px 34px ${theme.colors.border}`};
    position: absolute;
    top: 60%;
    left: 0;
    z-index: 2;
    transform: scale(0.85);
    @media ${device.md} {
        transform: scale(1);
        left: 0%;
    }
`;

const ImageRight = styled(Img)`
    box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
    border-radius: 10px;
`;

const SecondText = styled.span`
    padding-left: 0px;
    display: block;
`;

const Content2 = () => {
    const { referral } = useStaticQuery(graphql`
        {
            referral: file(relativePath: { eq: "home/referral.jpg" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return (
        <>
            {/* <!-- Content section 2 --> */}
            <Section
                bg="#f7f7fb"
                className="position-relative"
                id={"referrals"}
            >
                <ShapeTop>
                    <img src={svgCurve} alt="" className="img-fluid" />
                </ShapeTop>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="5" md="12" sm="12" className="mb-5 mb-lg-0">
                            <div className="position-relative">
                                <Box
                                    className="pr-lg-4 text-center text-lg-right"
                                    data-aos="fade-down"
                                    data-aos-duration="750"
                                    data-aos-once="true"
                                >
                                    <ImageRight
                                        fluid={referral.childImageSharp.fluid}
                                        alt=""
                                    />
                                </Box>

                                <ShapeCard
                                    bg="primary"
                                    p="18px"
                                    borderRadius={8}
                                    className="d-flex align-items-start"
                                    data-aos="fade-right"
                                    data-aos-duration="750"
                                    data-aos-once="true"
                                    data-aos-delay="100"
                                >
                                    <Box
                                        width="30px"
                                        minWidth="30px"
                                        height="30px"
                                        minHeight="30px"
                                        bg="secondary"
                                        color="light"
                                        borderRadius="50%"
                                        className="d-flex align-items-center justify-content-center"
                                        mr={3}
                                        mt={2}
                                    >
                                        <i className="fab fa-whatsapp"></i>
                                    </Box>

                                    <Box pr="40px">
                                        <Text
                                            color="light"
                                            lineHeight="24px"
                                            fontSize={1}
                                            mb={0}
                                            variant={"small"}
                                        >
                                            Hey Jen, Get $5 OFF on your first
                                            visit to Jerry Bistro at 30 King St.
                                            <SecondText>
                                                I love them! Cheers, Lisa
                                                <span
                                                    role="img"
                                                    aria-label="smile"
                                                >
                                                    😊
                                                </span>
                                            </SecondText>
                                        </Text>
                                    </Box>
                                </ShapeCard>
                            </div>
                        </Col>
                        <Col lg="7">
                            <div
                                data-aos="fade-left"
                                data-aos-duration="750"
                                data-aos-once="true"
                            >
                                <div className="content-text pl-lg--50">
                                    <div className="section-title">
                                        <Title>Referrals</Title>
                                        <Text mb={4} className="text-start">
                                            Turn your loyal customers into your
                                            brand advocates by offering instant
                                            rewards for referrals. Implementing
                                            a referral program for your business
                                            increases brand reputation and
                                            increases customer lifetime value.
                                            Automate and track your
                                            word-of-mouth marketing though
                                            built-in referral booster.
                                        </Text>
                                    </div>
                                    <WidgetContainer mt={2}>
                                        <Widget
                                            title="92% of consumers trust referrals from people they know."
                                            mb={4}
                                        ></Widget>
                                        <Widget
                                            title="When referred by other customers, people have a 37% higher
                    retention rate."
                                            mb={4}
                                        ></Widget>
                                        <Widget
                                            title="Friends’ social media posts influence the purchase decisions
                    of 83% of US online shoppers."
                                        ></Widget>
                                    </WidgetContainer>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    );
};

export default Content2;
