import React from "react";
import Hero from "../sections/landing1/Hero";
import Feature from "../sections/landing1/Feature";
import Feature2 from "../sections/landing2/Feature2";
import Content1 from "../sections/landing1/Content1";
import Content2 from "../sections/landing1/Content2";
import Content3 from "../sections/web/Content2";
import Content4 from "../sections/web/Content4";
import PageWrapper from "../components/PageWrapper";

const IndexPage = () => {
  return (
    <>
      <PageWrapper hasBanner footerDark>
        <Hero />
        <Feature />
        <Content1 />
        <Content2 />
        <Content3 />
        <Content4 />
        <Feature2 />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
