import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

import svgCurve from "../../assets/image/svg/l1-curve-content2.svg";
import feedContentImage from "../../assets/image/png/feedback-content.png";

const WidgetContainer = styled(Box)`
  counter-reset: widget-counter;
`;

const Widget = ({ iconImage, title = "", className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box
      mr={4}
      borderRadius="50%"
      color={"primary"}
      fontSize="28px"
      css={`
        background-color: "blue";
      `}
    >
      <i className={`icon fas fa-poll-h lh-1`}></i>
    </Box>
    <Box>
      <Title variant="card" mb={2}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

// const Widget = ({ num = 1, title = "", children = "", ...rest }) => {
//   return (
//     <WidgetStyled {...rest}>
//       <i className={`icon fas fa-poll-h`}></i>
//       <Title variant="card" mb={2}>
//         {title}
//       </Title>
//       <Text variant="small">{children}</Text>
//     </WidgetStyled>
//   );
// };

const ShapeTop = styled(Box)`
  position: absolute;
  top: 0;
  left: -150px;
  img {
    min-width: 100%;
  }
`;

const ShapeCard = styled(Box)`
  width: auto;
  box-shadow: ${({ theme }) => `0 32px 34px ${theme.colors.border}`};
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;
  transform: scale(0.85);
  @media ${device.sm} {
    transform: scale(1);
  }
  @media ${device.lg} {
    left: -6%;
  }
  @media ${device.xl} {
    left: -14%;
  }
`;

const ImageRight = styled(Box)`
  img {
    box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
    border-radius: 10px;
  }
`;

const SecondText = styled.span`
  padding-left: 0px;
  display: block;

  @media ${device.xl} {
    padding-left: 5px;
    display: initial;
  }
`;

const Content2 = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section bg="#f7f7fb" className="position-relative" id={"feedback"}>
      <ShapeTop>
        <img src={svgCurve} alt="" className="img-fluid" />
      </ShapeTop>
      <Container>
        <Row className="align-items-center">
          <Col lg="5" md="12" sm="12" className="mb-5 mb-lg-0">
            <div className="position-relative">
              <ImageRight
                className="pr-lg-4 text-center"
                data-aos="fade-down"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <img src={feedContentImage} alt="" className="img-fluid" />
              </ImageRight>
              <ShapeCard
                bg="primary"
                p="18px"
                borderRadius={8}
                className="d-flex align-items-center"
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="100"
              >
                <Box
                  width="30px"
                  minWidth="30px"
                  height="30px"
                  minHeight="30px"
                  bg="secondary"
                  color="light"
                  borderRadius="50%"
                  className="d-flex align-items-center justify-content-center"
                  mr={3}
                  mt={0}
                >
                  <i className="fas fa-bell"></i>
                </Box>

                <Box pr="40px">
                  <Text
                    color="white"
                    variant={"small"}
                    fontSize={1}
                    lineHeight="24px"
                  >
                    Thank you for your feedback!{" "}
                    <SecondText>You earned 10 points.</SecondText>
                  </Text>
                </Box>
              </ShapeCard>
            </div>
          </Col>
          <Col lg="6">
            <div
              data-aos="fade-left"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <div className="content-text pl-lg--50">
                <div className="section-title">
                  <Title>Feedback</Title>
                  <Text mb={4} className="text-start">
                    Collect customer feedback seamlessly with a built-in
                    feedback engine. No more boring lengthy surveys or customer
                    survey cards. RewardUp ensures that every customer leaves a
                    feedback that will help your business to encourage your
                    staffs and find areas to improve at a very early stage.
                    RewardUp enables your business with a detailed analysis and
                    reports of your customer feedback responses. Listening to
                    customer feedback always helps to build loyalty for the
                    brand.
                  </Text>
                </div>
                <WidgetContainer mt={2}>
                  <Widget
                    title="Listening to customer feedback makes customers feel involved
                    and important."
                    mb={4}
                  ></Widget>
                  <Widget
                    title="Good feedback generates personal recommendations and
                    referrals."
                    mb={4}
                  ></Widget>
                  <Widget
                    title="Customer feedback helps your business to convince the
                    customers to come back for more."
                  ></Widget>
                </WidgetContainer>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content2;
